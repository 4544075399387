import { PointType } from "../components/readings/Chart";

const getTickValues = (data: PointType[], count: number) => {
  let tickValues = [];
  if (data === undefined) return tickValues;
  const length = data.length;
  if (length < count) return tickValues;
  if (count === 1) return [data[0].x];

  // https://github.com/c3js/c3/blob/b94b2b5a51e308484a02f65471b76ef87e43c76d/src/axis.ts#L549
  const start = data[0].x;
  const end = data[length - 1].x;
  if (count === 2) return [start, end];

  if (count > 2) {
    count = count - 2;
    const interval = (end - start) / (count + 1);
    // re-construct unique values
    tickValues = [start];
    for (let i = 0; i < count; i++) {
      const tickValue = +start + interval * (i + 1);
      tickValues.push(tickValue);
    }
    tickValues.push(end);
  }

  return tickValues;
};

export { getTickValues };
