// Lets take a look for future support
// https://github.com/callstack/react-native-pager-view/issues/91

import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { View } from "react-native";

import { makeStyles, useTheme } from "@rneui/themed";
import Feather from "react-native-vector-icons/Feather";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useFocusEffect } from "@react-navigation/native";
import TouchableOpacity from "./TouchableOpacity";

interface IProps {
  initialPage: number;
  data;
  renderPage: (item, index: number) => React.ReactElement;
  hideDots?: boolean;
}

export interface ViewPagerRefInterface {
  nextPage: () => void;
  isLastIndex: () => boolean;
}

interface ArrowProps {
  onPress: () => void;
}

const ArrowPrev = ({ onPress }: ArrowProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  return (
    <TouchableOpacity
      style={[styles.button, styles.buttonLeft]}
      onPress={onPress}
    >
      <Feather name="chevron-left" size={32} color={theme.colors.tealBlue} />
    </TouchableOpacity>
  );
};
const ArrowNext = ({ onPress }: ArrowProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  return (
    <TouchableOpacity
      style={[styles.button, styles.buttonRight]}
      onPress={onPress}
    >
      <Feather name="chevron-right" size={32} color={theme.colors.tealBlue} />
    </TouchableOpacity>
  );
};

const ViewPager = forwardRef(
  (
    { initialPage, data, renderPage, hideDots = false }: IProps,
    ref: Ref<ViewPagerRefInterface>
  ) => {
    const styles = useStyles();

    const [currentPage, setCurrentPage] = useState<number>(initialPage);

    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === data.length - 1;

    const nextPageHandler = () => {
      const nextPage = currentPage + 1;
      if (nextPage < data.length) setCurrentPage(nextPage);
    };

    const prevPageHandler = () => {
      const nextPage = currentPage - 1;
      if (nextPage >= 0) setCurrentPage(nextPage);
    };

    useImperativeHandle(ref, () => ({
      nextPage: nextPageHandler,
      isLastIndex: () => {
        return currentPage + 1 >= data.length;
      }
    }));

    const onTouchMove = (e) => {
      e.preventDefault();
    };

    // Disabling scroll on safari, causing issues on horizontal swipe
    useFocusEffect(() => {
      document.body.addEventListener("touchmove", onTouchMove, {
        passive: false
      });
      return () => {
        document.body.removeEventListener("touchmove", onTouchMove);
      };
    });

    return (
      <View style={styles.container}>
        <Carousel
          showStatus={false}
          showThumbs={false}
          emulateTouch
          showIndicators={false}
          selectedItem={currentPage}
          renderArrowPrev={() =>
            isFirstPage ? undefined : <ArrowPrev onPress={prevPageHandler} />
          }
          renderArrowNext={() =>
            isLastPage ? undefined : <ArrowNext onPress={nextPageHandler} />
          }
          onChange={(page) => setCurrentPage(page)}
        >
          {data.map((item, index) => {
            return renderPage(item, index);
          })}
        </Carousel>

        {hideDots === false && (
          <View style={styles.dotContainer}>
            {data.map((item, index) => (
              <View
                key={item.key}
                style={
                  index === currentPage ? styles.activeDot : styles.inactiveDot
                }
              />
            ))}
          </View>
        )}
      </View>
    );
  }
);

ViewPager.displayName = "ViewPager";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%"
  },
  pagerView: {
    flex: 1
  },
  button: {
    position: "absolute",
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    zIndex: 10
  },
  buttonLeft: {
    left: -10
  },
  buttonRight: {
    right: -10
  },
  dotContainer: {
    flexDirection: "row",
    gap: 10,
    alignSelf: "center",
    marginBottom: 20
  },
  activeDot: {
    width: 30,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
    backgroundColor: theme.colors.tealBlue
  },
  inactiveDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
    backgroundColor: theme.colors.midBlue2
  }
}));

export default ViewPager;
