import {
  Chip as ChipLib,
  ChipProps,
  makeStyles,
  useTheme
} from "@rneui/themed";
import useTextStyles from "./styles/useTextStyles";

interface IProps extends ChipProps {
  iconName?: string;
}

const Chip = ({ iconName, ...props }: IProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const styles = useStyles();

  return (
    <ChipLib
      titleStyle={[textStyles.capsSmall, textStyles.colorDarkGreyBlue]}
      icon={
        iconName && {
          name: iconName,
          type: "feather",
          size: 16,
          color: theme.colors.darkGreyBlue
        }
      }
      buttonStyle={styles.chip}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      paddingVertical: 0,
      paddingHorizontal: 5
    }
  };
});

export default Chip;
