import { SetStateAction, useMemo, useState } from "react";
import { View, ViewStyle, StyleProp } from "react-native";
import { DateTime } from "luxon";
import { Tuple } from "victory";

import { ReadingAccessorType } from "common/hooks/useGetPatientDataSummary";
import DeviceReadingType from "common/types/DeviceReadingType";

import useTextStyles from "../ui/styles/useTextStyles";
import Spacing from "../ui/Spacing";
import Text from "../ui/Text";
import LocalizedStrings from "../../helpers/LocalizedStrings";

export interface ChartProps {
  height: number;
  width: number;
  zoomEnabled?: boolean;
  hasAxis?: boolean;
  header?: string;
  unit?: string;
  data: DeviceReadingType[];
  accessors: ReadingAccessorType[];
  accessorsMargin?: { x: number; y: number };
}

export interface DomainTupleType {
  x: Tuple<number>;
  y: Tuple<number>;
}

export interface PointType {
  x: number;
  y: number;
}

export const getStrokeColor = (theme, accessor: ReadingAccessorType) => {
  switch (accessor) {
    case "diastolic":
      return "#e67e22";
    case "systolic":
      return "#3498db";
    default:
      return theme.colors.greyBlue;
  }
};

export const useChartData = (
  data: DeviceReadingType[],
  accessors: ReadingAccessorType[]
) => {
  const [originalDomain, setOriginalDomain] = useState<DomainTupleType>();
  const [currentDomain, setCurrentDomain] = useState<DomainTupleType>();

  const chartDataByAccessor = useMemo(() => {
    if (data === undefined) return {};

    let newData = {};
    let globalMaxY = Number.MIN_VALUE;
    let globalMinY = Number.MAX_VALUE;
    let globalMinX = Number.MAX_VALUE;
    let globalMaxX = Number.MIN_VALUE;

    accessors.forEach((accessor) => {
      let maxY = Number.MIN_VALUE;
      let minY = Number.MAX_VALUE;
      let minX = Number.MAX_VALUE;
      let maxX = Number.MIN_VALUE;
      let sum = 0;

      const accessorData = data.map((reading) => {
        const x = reading.measure_timestamp * 1000;

        const y = reading[accessor];

        if (y > maxY) maxY = y;
        if (y < minY) minY = y;
        if (x > maxX) maxX = x;
        if (x < minX) minX = x;

        sum += y;

        return {
          x,
          y
        };
      });

      if (maxY > globalMaxY) globalMaxY = maxY;
      if (minY < globalMinY) globalMinY = minY;
      if (maxX > globalMaxX) globalMaxX = maxX;
      if (minX < globalMinX) globalMinX = minX;

      const average = data.length > 0 ? sum / data.length : undefined;
      newData[accessor] = {
        data: accessorData,
        average,
        maxX,
        minX,
        maxY,
        minY
      };
    });

    const domain: SetStateAction<DomainTupleType> = {
      x: [globalMinX - 1, globalMaxX + 1],
      y: [globalMinY - 5, globalMaxY + 20]
    };

    setOriginalDomain(domain);
    setCurrentDomain(domain);
    return { ...newData, maxX: globalMaxX, minX: globalMinX };
  }, [data, accessors]);

  const filteredChartData = useMemo(() => {
    if (chartDataByAccessor === undefined) return undefined;
    if (currentDomain === undefined) return chartDataByAccessor;

    // Filtering data for performance. A default filter won't work.
    // We need to include the edge points of the value filtering so we
    // don't experience disappearing lines when scrolling by day

    let filteredData = {};
    accessors.forEach((accessor) => {
      const accessorData = chartDataByAccessor[accessor].data;

      let startIndex = accessorData.findIndex(
        (item) => (currentDomain.x[0] as number) < item.x
      );
      let endIndex = accessorData.findIndex(
        (item) =>
          item.x > accessorData?.[startIndex]?.x &&
          item.x > (currentDomain.x[1] as number)
      );

      startIndex--;
      endIndex++;

      if (startIndex < 0) {
        startIndex = 0;
      }
      if (endIndex >= accessorData.length || endIndex <= 0) {
        // slice is not inclusive so we don't subtract one from length
        endIndex = accessorData.length;
      }

      filteredData[accessor] = accessorData.slice(startIndex, endIndex);
    });

    return filteredData;
  }, [chartDataByAccessor, currentDomain]);

  if (currentDomain === undefined) return {};

  const days = DateTime.fromMillis(currentDomain?.x[1] as number).diff(
    DateTime.fromMillis(currentDomain?.x[0] as number),
    "days"
  ).days;

  let tickCount = 4;
  let xAxisFormat = "M/d";
  if (days > 400) {
    xAxisFormat = "yyyy";
  } else if (days > 150) {
    tickCount = 5;
    xAxisFormat = "MMM";
  }

  if (filteredChartData[accessors[0]]?.length < 5) {
    tickCount = filteredChartData[accessors[0]]?.length;
  }

  return {
    filteredChartData,
    originalDomain,
    currentDomain,
    chartDataByAccessor,
    setCurrentDomain,
    xAxisFormat,
    tickCount
  };
};

export const RenderNotEnoughData = ({
  containerStyle
}: {
  containerStyle: StyleProp<ViewStyle>;
}) => {
  const textStyles = useTextStyles();

  return (
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    <View style={containerStyle}>
      <Spacing vertical={8} />
      <Text body style={[textStyles.textAlignCenter, textStyles.colorGrey0]}>
        {LocalizedStrings.screens.myReadings.notEnoughData}
      </Text>
      <Spacing vertical={4} />
    </View>
  );
};
