import React from "react";
import { makeStyles, useTheme } from "@rneui/themed";

import {
  TouchableWithoutFeedback,
  View,
  Keyboard,
  StyleProp,
  ViewStyle,
  Platform,
  LayoutChangeEvent
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";
import { useNetInfo } from "@react-native-community/netinfo";

import useKeyboard from "../../hooks/useKeyboard";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import Text from "./Text";
import useTextStyles from "./styles/useTextStyles";
import Spacing from "./Spacing";

interface IProps {
  onLayout?: (event: LayoutChangeEvent) => void;
  children?: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  innerStyle?: StyleProp<ViewStyle>;
  limitWidthTabletScreen?: boolean;
  disableBottomInsets?: boolean;
}

const BigScreenContainer = ({ limitWidthTabletScreen, children }) => {
  const { type } = useScreenType();
  const styles = useStyles();

  const viewStyle =
    type !== ScreenTypeEnum.PHONE && limitWidthTabletScreen
      ? styles.containerTablet
      : styles.flex1;

  return <View style={viewStyle}>{children}</View>;
};

const CloseKeyboardWrapper = ({ children }) => {
  const isVisible = useKeyboard();

  if (Platform.OS !== "web") {
    return (
      <TouchableWithoutFeedback
        accessible={false}
        onPress={Keyboard.dismiss}
        disabled={!isVisible}
      >
        {children}
      </TouchableWithoutFeedback>
    );
  } else {
    return <>{children}</>;
  }
};

const NoInternetConnection = () => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const styles = useStyles();

  return (
    <View style={styles.noInternetConnectionContainer}>
      <Feather name="wifi-off" size={128} color={theme.colors.tealBlue} />
      <Spacing vertical={10} />
      <Text h4 style={textStyles.colorDarkGreyBlue}>
        No internet connection
      </Text>
      <Spacing vertical={2} />
      <Text
        bodySmall
        style={[textStyles.colorDarkGreyBlue, styles.textAlignCenter]}
      >
        Please make sure your connection is working and try again
      </Text>
    </View>
  );
};

const ScreenContainer = ({
  onLayout,
  children,
  containerStyle = {},
  innerStyle = {},
  limitWidthTabletScreen,
  disableBottomInsets = false
}: IProps) => {
  const styles = useStyles();

  const insets = useSafeAreaInsets();
  const netInfo = useNetInfo();

  const { type } = useScreenType();
  const noTabBarInsets = { paddingBottom: insets.bottom };

  let safeAreaStyle = {};
  if (type !== ScreenTypeEnum.PHONE && !disableBottomInsets)
    safeAreaStyle = noTabBarInsets;

  return (
    <CloseKeyboardWrapper>
      <View onLayout={onLayout} style={[styles.container, containerStyle]}>
        <BigScreenContainer limitWidthTabletScreen={limitWidthTabletScreen}>
          <View style={[styles.flex1, safeAreaStyle, innerStyle]}>
            {netInfo.isInternetReachable === false ? (
              <NoInternetConnection />
            ) : (
              children
            )}
          </View>
        </BigScreenContainer>
      </View>
    </CloseKeyboardWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background
  },
  containerTablet: { flex: 1, width: 500, alignSelf: "center" },
  flex1: {
    flex: 1
  },
  noInternetConnectionContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 20,
    marginBottom: 100
  },
  textAlignCenter: {
    textAlign: "center"
  }
}));

export default ScreenContainer;
