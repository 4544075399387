import { useCallback, useMemo, useState } from "react";
import {
  ColorValue,
  Pressable,
  PressableProps,
  StyleProp,
  ViewStyle
} from "react-native";
import { LightenDarkenColor } from "lighten-darken-color";
import useGetCurrentColorScheme from "../../hooks/useGetCurrentColorScheme";

interface IProps extends PressableProps {
  style?: StyleProp<ViewStyle>;
  underlayColor: ColorValue;
}

const TouchableHighlight = ({
  children,
  underlayColor,
  style = {},
  ...props
}: IProps) => {
  const [isHover, setHover] = useState<boolean>(false);

  const colorScheme = useGetCurrentColorScheme();
  const lightenDarkenColorChange = colorScheme === "dark" ? 40 : -20;
  const pressedColor = useMemo(
    () => LightenDarkenColor(underlayColor, lightenDarkenColorChange),
    [underlayColor]
  );

  const pressableStyle = useCallback(
    ({ pressed }) => {
      let backgroundColor: ColorValue = "transparent";
      if (pressed || isHover) backgroundColor = pressedColor;
      return [
        style,
        {
          backgroundColor
        }
      ];
    },
    [style]
  );

  return (
    <Pressable
      style={pressableStyle}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      {...props}
    >
      {children}
    </Pressable>
  );
};

export default TouchableHighlight;
