import { useEffect, useState } from "react";
import { View } from "react-native";
import { DateTime } from "luxon";

import Feather from "react-native-vector-icons/Feather";

import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";

import Text from "../Text";
import { Divider, makeStyles, useTheme } from "@rneui/themed";
import Spacing from "../Spacing";
import Button from "../Button";
import useViewStyles from "../styles/useViewStyles";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DashboardStackParamList } from "../../../navigation/DashboardStackNavigator";

import useTextStyles from "../styles/useTextStyles";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import { getNameOrUsername } from "common/helpers/helpers";

interface IProps {
  appointment: CalendarEventResponseType;
}
type ScreenProp = NavigationProp<DashboardStackParamList>;

const INCOMING_MINUTES = 15;

const AppointmentCard = ({ appointment }: IProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<ScreenProp>();

  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  const dateTime = DateTime.fromISO(appointment?.startdate);
  const doctorName = getNameOrUsername(appointment.attendees?.[0]);

  const [appointmentIncoming, setAppointmentIncoming] =
    useState<boolean>(false);

  useEffect(() => {
    const now = DateTime.now();
    const { seconds: remainingSeconds } = dateTime.diff(now, "seconds");
    const remainingMinutes = remainingSeconds / 60;

    if (remainingMinutes > INCOMING_MINUTES)
      setTimeout(
        () => {
          if (remainingMinutes <= INCOMING_MINUTES)
            setAppointmentIncoming(true);
        },
        (remainingMinutes - INCOMING_MINUTES) * 60 * 1000
      );
    else setAppointmentIncoming(true);
  }, [dateTime]);

  const showCancelledConfirmationModal = () => {
    Alert_show({
      dispatch,
      title: "You have canceled the following appointment:",
      content: `${doctorName}${"\n"}${dateTime.toFormat("MM/dd/yyyy")} at ${dateTime.toFormat("hh:mm a")}.`
    });
  };

  const cancelAppointmentHandler = () => {
    Alert_show({
      dispatch,
      title: "Are you sure you want to cancel the appointment?",
      content: `You are about to cancel your appointment with ${doctorName}, on ${dateTime.toFormat("MM/dd/yyyy")} at ${dateTime.toFormat("hh:mm a")}.`,
      buttons: [
        {
          text: "Yes, Cancel Appointment",
          onPress: () => {
            showCancelledConfirmationModal();
          }
        },
        { text: "No, Go Back", style: "cancel" }
      ]
    });
  };

  return (
    <View
      style={[
        viewStyles.cardContainer,
        appointmentIncoming && styles.containerSecondary
      ]}
    >
      <View style={[styles.row, styles.center]}>
        <View style={styles.center}>
          <Text h2 style={textStyles.colorGreyBlue}>
            {dateTime.toFormat("dd")}
          </Text>
          <Text h2 style={textStyles.colorGreyBlue}>
            {dateTime.toFormat("MMM")}
          </Text>
        </View>
        <Divider orientation="vertical" color={theme.colors.greyBlue} />
        <View>
          <Text caps style={textStyles.colorGreyBlue}>
            {dateTime.toFormat("hh:mm a")}
          </Text>
          <Text body style={textStyles.colorGreyBlue}>
            {doctorName}
          </Text>
        </View>
      </View>

      <Spacing vertical={3} />

      {appointmentIncoming === false && (
        <>
          {/*<View style={styles.row}>
            <Feather name="info" size={20} color={theme.colors.greyBlue} />
            <Text bodySmall style={[textStyles.colorGreyBlue, styles.flex1]}>
              A button will appear here 15 minutes before the appointment
              starts.
            </Text>
      </View>
          <Spacing vertical={3} />*/}
        </>
      )}

      <View style={styles.row}>
        {appointmentIncoming ? (
          <>
            {/*<Button
              containerStyle={styles.flex1}
              title={"Enter Waiting Room"}
              onPress={() => navigation.navigate("VideoCall", { code: "TBD" })}
        />*/}
          </>
        ) : (
          <>
            {/*<Button
              containerStyle={styles.flex1}
              type="outline"
              title={"Reschedule"}
              onPress={() =>
                navigation.navigate("BookAppointment", {
                  isReschedule: true,
                  user_id: ""
                })
              }
            />
            <Button
              containerStyle={styles.flex1}
              type="outline"
              title={"Cancel"}
              onPress={cancelAppointmentHandler}
            />*/}
          </>
        )}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    containerSecondary: {
      backgroundColor: theme.colors.secondary,
      borderColor: "transparent"
    },
    center: {
      alignItems: "center"
    },
    row: {
      flexDirection: "row",
      gap: 10
    },
    flex1: {
      flex: 1
    }
  };
});

export default AppointmentCard;
