import { useCallback, useState } from "react";
import { Pressable, PressableProps, StyleProp, ViewStyle } from "react-native";

interface IProps extends PressableProps {
  style?: StyleProp<ViewStyle>;
}

const TouchableOpacity = ({ children, style = {}, ...props }: IProps) => {
  const [isHover, setHover] = useState<boolean>(false);

  const pressableStyle = useCallback(
    ({ pressed }) => {
      let opacity = 1;
      if (pressed) opacity = 0.2;
      else if (isHover) opacity = 0.7;
      return [
        style,
        {
          opacity
        }
      ];
    },
    [style, isHover]
  );

  return (
    <Pressable
      style={pressableStyle}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      {...props}
    >
      {children}
    </Pressable>
  );
};

export default TouchableOpacity;
