import { useEffect } from "react";
import { Linking, Platform } from "react-native";

import { useSelector } from "react-redux";
import { RootState } from "common/redux";

const USPSTrackingComponent = ({ route, navigation }) => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { trackingNumber } = route.params;

  const url =
    "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=" +
    trackingNumber;

  const handleBack = () => {
    if (navigation.canGoBack()) navigation.goBack();
    else {
      const route = isLoggedIn ? "Dashboard" : "Landing";
      navigation.reset({
        index: 0,
        routes: [{ name: route }]
      });
    }
  };

  const handleOpen = () => {
    if (Platform.OS === "web") {
      location.replace(url);
    } else {
      Linking.openURL(url);
    }

    handleBack();
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return null;
};

export default USPSTrackingComponent;
