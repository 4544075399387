export const onChange = ({ items, onValueChange, data }) => {
  if (items.length > 0) {
    const mappedItems = items.map((item) => {
      return data.find((completeItem) => completeItem.value === item);
    });

    const foundItem = mappedItems[mappedItems.length - 1];
    if (foundItem.clearOthers) {
      onValueChange([foundItem.value]);
    } else {
      onValueChange(
        mappedItems
          .filter((item) => item.clearOthers !== true)
          .map((item) => item.value)
      );
    }
  } else onValueChange(items);
};
