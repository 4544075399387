import SegmentedControlLib, {
  SegmentedControlProps
} from "@react-native-segmented-control/segmented-control";
import { makeStyles, useTheme } from "@rneui/themed";
import useTextStyles from "./styles/useTextStyles";

const SegmentedControl = (props: SegmentedControlProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <SegmentedControlLib
      tintColor={theme.colors.primary}
      backgroundColor={theme.colors.primary + "19"}
      activeFontStyle={{ ...styles.activeFont, ...textStyles.capsSmall }}
      fontStyle={{ ...styles.font, ...textStyles.capsSmall }}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  font: {
    color: theme.colors.darkGreyBlue
  },
  activeFont: {
    color: theme.colors.white
  }
}));

export default SegmentedControl;
