import "react-native-gesture-handler";
import { AppRegistry } from "react-native";
import {
  init,
  replayIntegration,
  browserTracingIntegration
} from "@sentry/react";

import EnvVars from "common/config/EnvVars";

import { name as appName } from "./app.json";
import App from "./src/App";
import "./index.css";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${window.location.origin}/service-worker.js`)
    .then(
      (registration) => {
        console.log("Service worker registration succeeded:", registration);
      },
      /*catch*/ (error) => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
}

// React Native Vector Icons init
//https://github.com/oblador/react-native-vector-icons#ios
// Generate required css
import FeatherIconsFont from "react-native-vector-icons/Fonts/Feather.ttf";
const featherFontStyles = `@font-face {
  src: url(${FeatherIconsFont});
  font-family: Feather;
}`;

// Create stylesheet
const style = document.createElement("style");
style.type = "text/css";
if (style.styleSheet) {
  style.styleSheet.cssText = featherFontStyles;
} else {
  style.appendChild(document.createTextNode(featherFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

// Sentry Init
if (!__DEV__) {
  init({
    dsn: EnvVars.REACT_APP_SENTRY_DSN,
    integrations: [browserTracingIntegration(), replayIntegration()],

    //https://docs.sentry.io/platforms/javascript/guides/react/session-replay/
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV
  });
}

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById("root")
});
