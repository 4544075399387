import { Helmet as HelmetLib } from "react-helmet";

export interface HelmetProps {
  hasAppleId?: boolean;
}

const Helmet = ({ hasAppleId = false }: HelmetProps) => {
  return (
    <HelmetLib>
      <title>Copilot IQ</title>
      {hasAppleId ? (
        <meta name="apple-itunes-app" content="app-id=1665724212" />
      ) : (
        <meta name="apple-itunes-app" content="app-id=null" />
      )}
    </HelmetLib>
  );
};

export default Helmet;
