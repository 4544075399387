import { makeStyles } from "@rneui/themed";

const commonPropsNavBarRounded = {
  shadowOpacity: 0.06,
  shadowOffset: { width: 0, height: 2 },
  shadowRadius: 1,
  elevation: 2
};

const useViewStyles = makeStyles((theme) => {
  return {
    navigationBarRounded: {
      ...commonPropsNavBarRounded,
      borderBottomEndRadius: 24,
      borderBottomStartRadius: 24,
      shadowColor: theme.colors.black
    },
    topBarRounded: {
      ...commonPropsNavBarRounded,
      borderTopEndRadius: 24,
      borderTopStartRadius: 24,
      shadowColor: theme.colors.black
    },
    cardContainer: {
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.grey3,
      padding: 20,
      backgroundColor: theme.colors.white,
      overflow: "hidden"
    }
  };
});

export default useViewStyles;
