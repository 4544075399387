import {
  CheckBox as CheckBoxLib,
  CheckBoxProps,
  makeStyles
} from "@rneui/themed";
import useTextStyles from "./styles/useTextStyles";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";

const CheckBox = ({
  checked,
  onPress,
  title,
  disabled,
  testID
}: CheckBoxProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  return (
    <CheckBoxLib
      {...AccessibilityHelper_getAccessibilityProps(testID)}
      size={32}
      iconType="feather"
      checkedIcon="check-square"
      uncheckedIcon="square"
      containerStyle={styles.container}
      textStyle={[textStyles.bodySmall, styles.text]}
      checked={checked}
      onPress={onPress}
      title={title}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 0,
      padding: 0,
      marginLeft: 0
    },
    disabled: {},
    text: {
      color: theme.colors.darkGreyBlue
    }
  };
});

export default CheckBox;
