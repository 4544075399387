import {
  VictoryChart,
  VictoryZoomContainer,
  VictoryTheme,
  VictoryAxis,
  VictoryContainer,
  VictoryLine,
  VictoryLegend,
  VictoryLabel,
  VictoryScatter
} from "victory";

import { DateTime, Duration } from "luxon";

import { useTheme } from "@rneui/themed";

import useTextStyles from "../ui/styles/useTextStyles";

import { Platform } from "react-native";
import { getTickValues } from "../../helpers/ChartHelpers";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import {
  ChartProps,
  DomainTupleType,
  RenderNotEnoughData,
  getStrokeColor,
  useChartData
} from "./Chart.shared";
import { Sentry_addBreadcrumb } from "common/helpers/SentryHelper";

const MARGIN = 40;

const Chart = ({
  hasAxis = false,
  data,
  accessors,
  width,
  height,
  header,
  unit,
  zoomEnabled = false,
  accessorsMargin = { x: 0, y: 0 }
}: ChartProps) => {
  Sentry_addBreadcrumb({
    category: "chart",
    message: "Rendering Chart - Legacy",
    level: "info"
  });

  const { theme } = useTheme();
  const { type, orientation } = useScreenType();
  const styles = useStyles(theme, {
    hasAxis,
    accessors,
    height,
    fullScreen: zoomEnabled,
    type,
    orientation
  });
  const textStyles = useTextStyles();

  const {
    filteredChartData,
    chartDataByAccessor,
    currentDomain,
    setCurrentDomain,
    xAxisFormat,
    tickCount
  } = useChartData(data, accessors);

  if (
    data === undefined ||
    (!hasAxis && data.length < 2) ||
    (hasAxis && data.length === 0)
  ) {
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    return <RenderNotEnoughData containerStyle={styles.noDataContainer} />;
  }

  if (currentDomain === undefined) return;

  const tickValues = getTickValues(filteredChartData[accessors[0]], tickCount);

  const zoomDomainChangeHandler = (newDomain: DomainTupleType) => {
    const { minX, maxX } = chartDataByAccessor[accessors[0]];

    if (newDomain.x[0] < minX || newDomain.x[1] > maxX) return;

    setCurrentDomain(newDomain);
  };

  const tickLabelsStyle = {
    ...textStyles.capsSmall,
    fill: theme.colors.darkGreyBlue
  };

  const legendStyle = {
    ...textStyles.h4Style,
    ...textStyles.colorDarkGreyBlue
  };

  // Fixing issue that makes the number 4 to render incorrectly on android.
  // Seems that the font is not supported for charts.
  if (Platform.OS === "android") {
    delete tickLabelsStyle.fontFamily;
    delete legendStyle.fontFamily;
  }

  const chartStyle = styles.chart;
  let chartWidth = width;
  if (hasAxis === false && width !== undefined) {
    chartStyle.marginLeft = -MARGIN;
    chartWidth = width + MARGIN;
  }

  return (
    <VictoryChart
      width={chartWidth}
      height={height}
      // Do not use domain padding on web because it affects the data displayed.
      // https://www.notion.so/copilotiq/CopilotIQ-v1-1-1-Automated-Tests-Exploratory-Tests-on-Staging-2afb883ea3044a7d92b70c7e401df764
      // issue 25.
      domainPadding={{ x: 5 }}
      containerComponent={
        zoomEnabled ? (
          <VictoryZoomContainer
            minimumZoom={{ x: Duration.fromObject({ week: 1 }).toMillis() }}
            zoomDimension="x"
            onZoomDomainChange={zoomDomainChangeHandler}
          />
        ) : (
          <VictoryContainer
            responsive={false}
            style={
              Platform.OS === "web"
                ? {
                    ...chartStyle,
                    touchAction: "auto",
                    pointerEvents: "auto",
                    userSelect: "auto"
                  }
                : chartStyle
            }
          />
        )
      }
      theme={VictoryTheme.material}
    >
      {header !== undefined && (
        <VictoryLegend
          // TODO: We may need to calculate the width of the text in a better way.
          x={(chartWidth - header.length * 10) / 2}
          y={0}
          title={header}
          style={{
            title: legendStyle
          }}
          centerTitle
          orientation="horizontal"
          data={[]}
        />
      )}

      {accessors.length > 1 && (
        <VictoryLegend
          x={accessorsMargin.x}
          y={accessorsMargin.y}
          centerTitle
          orientation="horizontal"
          data={accessors.map((accessor) => ({
            name: accessor.toLocaleUpperCase(),
            symbol: { fill: getStrokeColor(theme, accessor) }
          }))}
        />
      )}

      {hasAxis ? (
        <>
          <VictoryAxis
            crossAxis
            scale={"time"}
            width={chartWidth}
            height={height}
            theme={VictoryTheme.material}
            standalone={false}
            domain={currentDomain?.x}
            fixLabelOverlap
            tickValues={tickValues}
            tickFormat={(x) => {
              if (typeof x !== "number") {
                return DateTime.fromJSDate(x).toFormat(xAxisFormat);
              } else {
                return DateTime.fromMillis(x).toFormat(xAxisFormat);
              }
            }}
            style={{
              axis: styles.strokeTransparent,
              ticks: styles.strokeTransparent,
              tickLabels: tickLabelsStyle,
              grid: styles.strokeTransparent
            }}
          />

          <VictoryAxis
            dependentAxis
            crossAxis
            width={chartWidth}
            height={height}
            theme={VictoryTheme.material}
            standalone={false}
            domain={currentDomain?.y}
            label={unit}
            axisLabelComponent={<VictoryLabel dy={-32} angle={-90} />}
            style={{
              axis: styles.strokeTransparent,
              ticks: styles.strokeTransparent,
              tickLabels: tickLabelsStyle,
              grid: { stroke: theme.colors.grey1, strokeDasharray: "0 0" }
            }}
          />
        </>
      ) : (
        <VictoryAxis
          crossAxis
          theme={VictoryTheme.material}
          standalone={false}
          style={{
            axis: styles.strokeTransparent,
            ticks: styles.strokeTransparent,
            tickLabels: styles.fillTransparent,
            grid: styles.strokeTransparent
          }}
        />
      )}

      {/* We need to leave this, otherwise the zoom controls don't work properly... 
      Yes, I know, it is nonsense but that is how I got it working :/ */}
      {accessors.map((accessor) => {
        return (
          <VictoryLine
            key={accessor + "line"}
            data={[
              {
                x: chartDataByAccessor[accessor].minX,
                y: chartDataByAccessor[accessor].average
              },
              {
                x: chartDataByAccessor[accessor].maxX,
                y: chartDataByAccessor[accessor].average
              }
            ]}
            style={{
              data: {
                strokeWidth: 0
              }
            }}
          />
        );
      })}

      {accessors.map((accessor) => {
        const data = filteredChartData[accessor];
        if (data.length === 1)
          return (
            <VictoryScatter
              key={accessor + "scatter"}
              scale={{ x: "time", y: "linear" }}
              style={{
                data: {
                  ...styles.chartLine,
                  stroke: getStrokeColor(theme, accessor)
                }
              }}
              data={data}
              domain={currentDomain}
            />
          );
        else
          return (
            <VictoryLine
              key={accessor + "area"}
              scale={{ x: "time", y: "linear" }}
              interpolation="bumpX"
              style={{
                data: {
                  ...styles.chartLine,
                  stroke: getStrokeColor(theme, accessor)
                }
              }}
              data={data}
              domain={currentDomain}
            />
          );
      })}
    </VictoryChart>
  );
};

const useStyles = (
  theme,
  { hasAxis, accessors, height, fullScreen, type, orientation }
) => {
  const axisHeight = hasAxis ? 0 : MARGIN;
  const accessorsHeight =
    accessors.length > 1 && type === ScreenTypeEnum.PHONE ? MARGIN : 0;

  const noDataHeight = height - axisHeight - accessorsHeight;

  return {
    chart: {
      marginLeft: 0,
      marginTop: fullScreen ? 0 : -MARGIN,
      // Not adding padding on web because button is not clickable.
      marginBottom: hasAxis || Platform.OS === "web" ? 0 : -MARGIN
    },
    chartLine: {
      stroke: theme.colors.darkGreyBlue,
      strokeWidth: 2
    },
    strokeTransparent: {
      stroke: "transparent"
    },
    fillTransparent: {
      fill: "transparent"
    },
    noDataContainer: {
      justifyContent: "center",
      alignItems: "center",
      height: noDataHeight
    }
  };
};

export default Chart;
