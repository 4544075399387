import { forwardRef, Ref, useMemo, useState } from "react";
import { Platform, TextInput, View } from "react-native";
import { Input as BaseInput } from "@rneui/base";
import { Input as InputLib, InputProps, makeStyles } from "@rneui/themed";

import useTextStyles from "./styles/useTextStyles";
import Text from "./Text";
import { maskPhoneNumber, unmaskPhoneNumber } from "common/helpers/helpers";
import Spacing from "./Spacing";

const PhoneInput = forwardRef(
  (props: InputProps, ref: Ref<TextInput & BaseInput>) => {
    const [isFocused, setFocused] = useState<boolean>(false);

    const styles = useStyles({ isFocused, ...props });
    const textStyles = useTextStyles();

    const formattedPhone = useMemo(() => {
      const maskedPhoneNumber = maskPhoneNumber(props.value);
      if (maskedPhoneNumber) return maskedPhoneNumber;
      else return props.value;
    }, [props.value]);

    const { label, ...inputProps } = props;

    return (
      <View>
        {label !== undefined && (
          <Text style={[textStyles.capsSmall, styles.label]}>{label}</Text>
        )}
        <View style={styles.inputContainer}>
          <Text body style={styles.countryCodeText}>
            +1
          </Text>
          <View style={styles.inputWrapper}>
            <InputLib
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              accessibilityLabel={"PhoneInput"}
              inputContainerStyle={styles.disableBorder}
              inputStyle={[textStyles.bodySmall, styles.input]}
              errorStyle={[textStyles.bodySmall, styles.error]}
              {...inputProps}
              value={formattedPhone}
              onChangeText={(text: string) => {
                const unmaskedPhone = unmaskPhoneNumber(text);
                props.onChangeText(unmaskedPhone);
              }}
              textContentType="telephoneNumber"
              inputMode="tel"
              maxLength={16}
              ref={ref}
            />
          </View>
        </View>

        {inputProps.errorMessage?.length > 0 && <Spacing vertical={3} />}
      </View>
    );
  }
);

interface StyleParams extends InputProps {
  isFocused: boolean;
}

const disableWebOutline = Platform.select({
  web: {
    outlineWidth: 0,
    outlineStyle: "solid"
  }
});

const getBorderColor = (theme, isFocused, isError) => {
  if (isError) return theme.colors.error;
  else if (isFocused) return theme.colors.tealBlue;
  else return theme.colors.grey3;
};

const useStyles = makeStyles(
  (theme, { isFocused, errorMessage }: StyleParams) => {
    const borderColor = getBorderColor(
      theme,
      isFocused,
      errorMessage != undefined
    );

    return {
      container: {
        paddingHorizontal: 0
      },
      inputWrapper: {
        flex: 1
      },
      input: {
        ...disableWebOutline
      },
      inputContainer: {
        backgroundColor: theme.colors.background,
        borderWidth: 1,
        borderColor,
        borderRadius: 10,
        flexDirection: "row"
      },
      label: {
        color: theme.colors.darkGreyBlue,
        marginLeft: 5,
        marginBottom: 5
      },
      error: {
        color: theme.colors.error,
        height: errorMessage ? undefined : 0,
        margin: 0,
        marginLeft: -40
      },

      disableBorder: {
        borderBottomWidth: 0
      },
      countryCodeText: {
        alignSelf: "center",
        marginLeft: 10
      }
    };
  }
);

export default PhoneInput;
