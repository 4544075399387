import { useEffect, useMemo } from "react";
import { View, Image } from "react-native";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@rneui/themed";
import { useSafeAreaInsets, EdgeInsets } from "react-native-safe-area-context";
import Feather from "react-native-vector-icons/Feather";
import { useNetInfo } from "@react-native-community/netinfo";

import { logOut } from "common/redux/AuthSlice";
import { RootState, useAppDispatch } from "common/redux";

import { copilotIQLogo } from "../../../assets/common";
import Text from "../Text";
import useViewStyles from "../styles/useViewStyles";
import { DrawerActions, useNavigationState } from "@react-navigation/native";
import useScreenType, {
  ScreenOrientationTypeEnum,
  ScreenTypeEnum
} from "../../../hooks/useScreenType";
import TouchableOpacity from "../TouchableOpacity";

interface IProps extends NativeStackHeaderProps {
  rounded?: boolean;
  hideMenuToggleOnTablet?: boolean;
  hideBackButton?: boolean;
}

const CopilotIQLogoRoutes = [
  "Landing",
  "PhoneInput",
  "SMSCode",
  "MissingLegalForms",
  "Biometrics",
  "Reauthentication",
  "TurnOnNotifications",
  "Dashboard"
];

const LoginResetRoutes = ["Biometrics"];

const NotRoundedRoutes = ["Dashboard"];

const NavigationBar = ({
  options,
  route,
  navigation,
  hideMenuToggleOnTablet,
  hideBackButton
}: IProps) => {
  const dispatch = useAppDispatch();
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const viewStyles = useViewStyles();

  const netInfo = useNetInfo();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const isRounded = useMemo(() => {
    return !NotRoundedRoutes.includes(route.name);
  }, [route, NotRoundedRoutes]);

  const showsLogo = useMemo(() => {
    return CopilotIQLogoRoutes.includes(route.name);
  }, [route, CopilotIQLogoRoutes]);

  const shouldResetLoginOnBack = useMemo(() => {
    return LoginResetRoutes.includes(route.name);
  }, [route, LoginResetRoutes]);

  const { type, orientation } = useScreenType();

  const index = useNavigationState((state) => state.index);
  const canGoBack = index !== 0 && hideBackButton !== true;

  useEffect(() => {
    // close drawer menu on tablet when rotating screen
    if (type === ScreenTypeEnum.TABLET && isLoggedIn) {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
  }, [orientation, type, isLoggedIn]);

  const { navigationBarHidden, title } = options;
  if (navigationBarHidden) return null;
  if (netInfo.isInternetReachable === false) return null;
  return (
    <View style={styles.squareContainer}>
      <View
        style={[
          styles.navBarContainer,
          isRounded && viewStyles.navigationBarRounded
        ]}
      >
        <TouchableOpacity
          style={styles.sideContainer}
          disabled={!canGoBack}
          onPress={async () => {
            // if we are in the middle of the login flow and the user presses 'back',
            // we want to reset the flow and start at the beginning
            if (shouldResetLoginOnBack) {
              await dispatch(logOut(true)).then(() => {
                canGoBack && navigation.popToTop();
              });
            } else {
              canGoBack && navigation.goBack();
            }
          }}
        >
          {canGoBack && (
            <>
              <Feather
                name="chevron-left"
                size={30}
                color={theme.colors.tealBlue}
              />
              <Text accessibilityLabel={"Back"} bodySmall>
                {"Back"}
              </Text>
            </>
          )}

          {!canGoBack &&
            type === ScreenTypeEnum.TABLET &&
            orientation === ScreenOrientationTypeEnum.PORTRAIT &&
            !hideMenuToggleOnTablet && (
              <TouchableOpacity
                style={styles.leftButton}
                onPress={() =>
                  navigation.dispatch(DrawerActions.toggleDrawer())
                }
              >
                <Feather name="menu" size={30} color={theme.colors.tealBlue} />
              </TouchableOpacity>
            )}
        </TouchableOpacity>

        {showsLogo ? (
          <Image
            style={styles.image}
            resizeMode={"center"}
            source={copilotIQLogo}
          />
        ) : (
          <Text
            bodyLarge
            style={[styles.headerText, styles.flex1]}
            allowFontScaling={false}
            adjustsFontSizeToFit
          >
            {title || route.name}
          </Text>
        )}
        <View style={styles.sideContainer}></View>
      </View>
      <View style={styles.iosStatusBar} />
    </View>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => ({
  flex1: {
    flex: 1
  },
  squareContainer: {
    backgroundColor: theme.colors.background,
    flexDirection: "row"
  },
  leftButton: {
    marginLeft: 10
  },
  navBarContainer: {
    flex: 1,
    backgroundColor: theme.colors.white,
    height: insets.top + 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: insets.top
  },
  iosStatusBar: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: insets.top,
    backgroundColor: theme.colors.darkBlue
  },
  sideContainer: {
    flex: 0.5,
    height: 50,
    flexDirection: "row",
    alignItems: "center"
  },
  headerText: {
    color: theme.colors.grey0,
    textAlign: "center"
  },
  image: {
    width: 154,
    height: 50
  }
}));

export default NavigationBar;
