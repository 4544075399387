import { makeStyles, useTheme } from "@rneui/themed";
import { ViewStyle, View } from "react-native";

import Feather from "react-native-vector-icons/Feather";

import Text from "../ui/Text";
import TouchableOpacity from "../ui/TouchableOpacity";

interface IProps {
  icon: string;
  text: string;
  style?: ViewStyle;
  linkText?: string;
  onLinkPress?: () => void;
}

const HeaderIconText = ({
  icon,
  text,
  style,
  linkText,
  onLinkPress
}: IProps) => {
  const { theme } = useTheme();
  const styles = useStyles();

  return (
    <View style={[styles.row, style]}>
      <Feather name={icon} color={theme.colors.darkGreyBlue} size={32} />
      <Text h4>{text}</Text>
      <View style={styles.flex1} />
      <TouchableOpacity
        onPress={onLinkPress}
        disabled={onLinkPress === undefined}
      >
        <Text link>{linkText}</Text>
      </TouchableOpacity>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    row: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10,
      marginBottom: 10
    },
    flex1: {
      flex: 1
    }
  };
});

export default HeaderIconText;
