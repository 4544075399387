import { useEffect } from "react";
import { Text, TextInput, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ErrorBoundary from "react-native-error-boundary";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { enableScreens } from "react-native-screens";

import ReduxWrapper from "common/redux/ReduxWrapper";
import { store } from "common/redux";
import {
  SentryHelper_captureException,
  Sentry_wrap
} from "common/helpers/SentryHelper";

import {
  PushNotificationsHelper_init,
  PushNotificationsHelper_destroy
} from "common/helpers/firebase/PushNotificationsHelper";

import Router from "./navigation/Router";
import ReactNativeTheme from "./theme/Theme";
import ErrorScreen from "./screens/ErrorScreen";
import Helmet from "./components/Helmet";
import Fonts from "./constants/Fonts";

// https://github.com/software-mansion/react-native-screens/issues/1366#issuecomment-1075316399
enableScreens(Platform.OS !== "ios");

const MAX_FONT_SIZE_MULTIPLIER = 1.5;
// @ts-ignore
Text.defaultProps = {
  // @ts-ignore
  ...Text.defaultProps,
  maxFontSizeMultiplier: MAX_FONT_SIZE_MULTIPLIER,
  fontSize: 18,
  fontFamily: Fonts.Inter[400],
  fontWeight: "400"
};

// @ts-ignore
TextInput.defaultProps = {
  // @ts-ignore
  ...Text.defaultProps,
  maxFontSizeMultiplier: MAX_FONT_SIZE_MULTIPLIER,
  fontSize: 18,
  fontFamily: Fonts.Inter[400],
  fontWeight: "400"
};

const App = () => {
  useEffect(() => {
    PushNotificationsHelper_init();

    return () => {
      PushNotificationsHelper_destroy();
    };
  }, []);

  return (
    <ReduxWrapper store={store}>
      <ReactNativeTheme>
        <Helmet hasAppleId />
        <SafeAreaProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <ErrorBoundary
              FallbackComponent={ErrorScreen}
              onError={(error: Error) => {
                SentryHelper_captureException(error);
              }}
            >
              <Router />
            </ErrorBoundary>
          </GestureHandlerRootView>
        </SafeAreaProvider>
      </ReactNativeTheme>
    </ReduxWrapper>
  );
};

export default Sentry_wrap(App);
