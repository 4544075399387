import { useEffect } from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";
import { DateTime } from "luxon";

import {
  LocalizationProvider,
  DatePicker as DatePickerMUI
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import Text from "./Text";

interface IProps {
  title: string;
  date: DateTime;
  initialDate?: DateTime;
  onDateChanged: (date: DateTime) => void;
  format?: string;
  mode?: "date" | "datetime" | "time";
}

const DatePicker = ({
  title,
  date,
  onDateChanged,
  initialDate = DateTime.now(),
  format = "MM/dd/yyyy",
  mode = "date"
}: IProps) => {
  const styles = useStyles();

  useEffect(() => {
    onDateChanged(initialDate);
  }, []);

  return (
    <View>
      <Text capsSmall style={styles.label}>
        {title}
      </Text>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePickerMUI
          value={date}
          onChange={(newValue) => onDateChanged(newValue)}
        />
      </LocalizationProvider>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.colors.darkGreyBlue,
    marginLeft: 5,
    marginBottom: 5
  },
  calendarIcon: {
    position: "absolute",
    right: 10,
    top: 10
  }
}));

export default DatePicker;
