import { makeStyles, useTheme } from "@rneui/themed";
import { View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import Spacing from "./Spacing";
import Text from "./Text";
import { maskPhoneNumber, unmaskPhoneNumber } from "common/helpers/helpers";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import { useAppDispatch } from "common/redux";

interface IProps {
  phone?: string;
}

const PhoneCallComponent = ({ phone = "+18889201588" }: IProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const dispatch = useAppDispatch();

  let phoneNumber = unmaskPhoneNumber(phone);
  // if unmasked number doesn't have country code, add it
  if (!phoneNumber.includes("+1")) phoneNumber = "+1" + phoneNumber;
  const phoneUrl = `tel:${phoneNumber}`;
  return (
    <View style={[styles.row, styles.center]}>
      <Feather name={"phone-call"} size={24} color={theme.colors.tealBlue} />
      <Spacing horizontal={2} />
      <Text h4 link onPress={() => Linking_openURL(phoneUrl, dispatch)}>
        {maskPhoneNumber(phone)}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    row: {
      flexDirection: "row"
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

export default PhoneCallComponent;
