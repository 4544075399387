import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import Text from "./Text";
import CheckBox from "./CheckBox";
import MemberType from "common/types/MemberType";
import { getNameOrUsername, maskPhoneNumber } from "common/helpers/helpers";
import useTextStyles from "./styles/useTextStyles";

interface IProps {
  editable?: boolean;
  checked?: boolean;
  onPress?: () => void;
  // TODO: Just for testing, we will replace this later
  doctor: MemberType;
}

const DoctorInfo = ({ checked, editable = false, onPress, doctor }: IProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View style={styles.row}>
      {editable ? (
        <CheckBox checked={checked} onPress={onPress} />
      ) : (
        <View style={styles.dot} />
      )}

      <View style={styles.textContainer}>
        <Text h4 style={textStyles.colorDarkGreyBlue}>
          {getNameOrUsername(doctor.patient, false, false)}
        </Text>
        <Text bodySmall style={textStyles.colorDarkGreyBlue}>
          {doctor.patient.timezone}
        </Text>
        <Text bodySmall style={textStyles.colorDarkGreyBlue}>
          {maskPhoneNumber(doctor.patient.phone)}
        </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    row: {
      marginVertical: 10,
      flexDirection: "row"
    },
    dot: {
      marginTop: 10,
      marginRight: 10,
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.colors.darkGreyBlue
    },
    textContainer: {
      flex: 1,
      marginVertical: -1,
      gap: 5
    }
  };
});

export default DoctorInfo;
