import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { DateTime } from "luxon";

import DatePicker from "../ui/DatePicker";
import Input from "../ui/Input";
import { makeStyles } from "@rneui/themed";

interface Data {
  goalName: string;
  description: string;
  date: DateTime;
}

interface IProps {
  onValueChange: ({ goalName, description, date }: Data) => void;
}

const GoalForm = ({ onValueChange }: IProps) => {
  const styles = useStyles();

  const [goalName, setGoalName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [date, setDate] = useState<DateTime>();

  useEffect(() => {
    onValueChange({ goalName, description, date });
  }, [goalName, description, date]);

  return (
    <View style={styles.gap}>
      <Input
        value={goalName}
        onChangeText={(text) => setGoalName(text)}
        label="GOAL NAME"
        placeholder="Placeholder"
      />
      <Input
        value={description}
        onChangeText={(text) => setDescription(text)}
        label="DESCRIPTION"
        placeholder="Placeholder"
        multiline
      />
      <DatePicker
        title="GOAL DATE"
        onDateChanged={(date: DateTime) => setDate(date)}
        date={date}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    gap: {
      gap: 20
    }
  };
});

export default GoalForm;
