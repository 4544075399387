import { View } from "react-native";

const Spacing = ({ horizontal = 1, vertical = 1 }) => {
  return (
    <View
      style={{
        width: 5 * horizontal,
        height: 5 * vertical
      }}
    />
  );
};

export default Spacing;
