import {
  View,
  Modal as ModalLib,
  Platform,
  StyleProp,
  ViewStyle
} from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

interface IProps {
  visible: boolean;
  onRequestClose: () => void;
  children: React.ReactElement;
  containerStyle?: StyleProp<ViewStyle>;
}

const Modal = ({
  visible,
  onRequestClose,
  children,
  containerStyle
}: IProps) => {
  const { theme } = useTheme();
  const styles = useStyles(theme);

  return (
    <ModalLib
      visible={visible}
      // https://github.com/facebook/react-native/issues/34184#issuecomment-1565101930
      animationType={Platform.OS === "ios" ? "none" : "fade"}
      hardwareAccelerated={Platform.OS !== "web"}
      onRequestClose={onRequestClose}
      transparent
    >
      <View style={containerStyle || styles.backdrop}>{children}</View>
    </ModalLib>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#00000099"
    }
  };
});

export default Modal;
