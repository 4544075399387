import {
  ActivityIndicatorProps,
  ActivityIndicator as ActivityIndicatorRN
} from "react-native";
import { useTheme } from "@rneui/themed";

const ActivityIndicator = (props: ActivityIndicatorProps) => {
  const { theme } = useTheme();
  return <ActivityIndicatorRN color={theme.colors.tealBlue} {...props} />;
};

export default ActivityIndicator;
