import React, { forwardRef, Ref, useState } from "react";
import { Platform, TextInput, TextStyle, StyleProp } from "react-native";
import { Input as BaseInput } from "@rneui/base";
import { Input as InputLib, InputProps, makeStyles } from "@rneui/themed";

import useTextStyles from "./styles/useTextStyles";

const MIN_HEIGHT = 50;
const MAX_HEIGHT = 150;

const Input = forwardRef(
  (props: InputProps, ref: Ref<TextInput & BaseInput>) => {
    const [isFocused, setFocused] = useState<boolean>(false);
    const [scrollHeight, setScrollHeight] = useState<number>();

    const styles = useStyles({
      ...props,
      isFocused,
      errorMessage: props?.errorMessage,
      multiline: props?.multiline
    });
    const textStyles = useTextStyles();

    const inputStyle =
      Platform.OS === "web"
        ? [
            textStyles.bodySmall,
            styles.input,
            { outlineWidth: 0, outlineStyle: "solid", height: scrollHeight }
          ]
        : [textStyles.bodySmall, styles.input];

    return (
      <InputLib
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        inputStyle={inputStyle as StyleProp<TextStyle>}
        onChange={(event) => {
          // https://github.com/necolas/react-native-web/issues/795#issuecomment-576441034
          // @ts-ignore
          Platform.OS === "web" && setScrollHeight(event.target.scrollHeight);
        }}
        inputContainerStyle={styles.inputContainer}
        containerStyle={styles.container}
        errorStyle={[textStyles.bodySmall, styles.error]}
        labelStyle={
          props.label !== undefined
            ? [textStyles.capsSmall, styles.label]
            : { height: 0 }
        }
        {...props}
        ref={ref}
      />
    );
  }
);

interface StyleParams extends InputProps {
  isFocused: boolean;
}

const getBorderColor = (theme, props: StyleParams) => {
  if (props.disabled) return theme.colors.grey3;
  else if (props.errorMessage != undefined) return theme.colors.error;
  else if (props.isFocused) return theme.colors.tealBlue;
  else return theme.colors.grey3;
};

const useStyles = makeStyles((theme, props: StyleParams) => {
  const borderColor = getBorderColor(theme, props);

  return {
    container: {
      paddingHorizontal: 0
    },
    input: {
      minHeight: 25,
      maxHeight: MAX_HEIGHT
    },
    inputContainer: {
      backgroundColor: theme.colors.background,
      borderWidth: 1,
      borderColor,
      minHeight: MIN_HEIGHT,
      maxHeight: MAX_HEIGHT,
      borderRadius: 16,
      paddingStart: 15,
      paddingHorizontal: 10
    },
    label: {
      color: theme.colors.darkGreyBlue,
      marginLeft: 5,
      marginBottom: 5
    },
    error: {
      color: theme.colors.error,
      height: props.errorMessage ? undefined : 0,
      margin: 0
    }
  };
});

export default Input;
