import { useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import { DateTime } from "luxon";

import Text from "../../ui/Text";
import { makeStyles, useTheme } from "@rneui/themed";
import useTextStyles from "../../ui/styles/useTextStyles";
import { AnalyticsHelper_logEvent } from "common/helpers/firebase/AnalyticsHelper";
import TouchableOpacity from "../../ui/TouchableOpacity";

interface ListItemProps {
  selected: boolean;
  date: DateTime;
  index: number;
  onPress: (date: DateTime) => void;
}

interface IProps {
  onStartDateChanged: (date: DateTime) => void;
  onEndDateChanged: (date: DateTime) => void;
  mixpanelType: string;
}

const ListItem = ({ selected, date, index, onPress }: ListItemProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();
  const selectedColor = selected ? theme.colors.tealBlue : theme.colors.grey3;
  const backgroundColor = selected
    ? theme.colors.tealBlue + "1C"
    : "transparent";
  const marginStyle =
    index % 2 === 0
      ? { marginRight: 10, borderColor: selectedColor, backgroundColor }
      : { marginLeft: 10, borderColor: selectedColor, backgroundColor };
  return (
    <TouchableOpacity
      style={[styles.listItemContainer, marginStyle]}
      onPress={() => onPress(date)}
    >
      <Text
        capsSmall
        style={textStyles.colorTealAAA}
        accessibilityLabel={date.toFormat("MMM yyyy").toUpperCase()}
      >
        {date.toFormat("MMM yyyy").toUpperCase()}
      </Text>
    </TouchableOpacity>
  );
};

const DateRangePickerMonthList = ({
  onStartDateChanged,
  onEndDateChanged,
  mixpanelType
}: IProps) => {
  const styles = useStyles();

  const [selectedMonth, setSelectedMonth] = useState<DateTime>();
  const [data, setData] = useState<DateTime[]>();

  useEffect(() => {
    const MONTH_QUANTITY = 12;
    const monthsArray = [];
    [...Array(MONTH_QUANTITY)].forEach((item, index) => {
      monthsArray.push(DateTime.now().minus({ month: index }));
    });

    setData([...monthsArray]);
  }, []);

  useEffect(() => {
    if (selectedMonth === undefined) return;

    AnalyticsHelper_logEvent(mixpanelType + "_CalendarPicker_Monthly");

    onStartDateChanged(selectedMonth.startOf("month"));
    onEndDateChanged(selectedMonth.endOf("month"));
  }, [selectedMonth]);

  return (
    <FlatList
      data={data}
      numColumns={2}
      keyExtractor={(item, index) => item.toISODate()}
      showsVerticalScrollIndicator={false}
      renderItem={({ item, index }) => (
        <ListItem
          selected={selectedMonth === item}
          date={item}
          index={index}
          onPress={setSelectedMonth}
        />
      )}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    listItemContainer: {
      flex: 1,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "red",
      padding: 10,
      justifyContent: "center",
      alignItems: "center"
    },
    separator: {
      height: 10,
      width: 10
    }
  };
});

export default DateRangePickerMonthList;
