enum ResponsiveBreakpoints {
  // Sidebar width breakpoint (dp)
  // Standard breakpoint for tablet is 768.
  // The thing is that iPad mini is 744 width, and it might be smaller tablets on
  // android. That's why I chose 600. Also the biggest phone (IPhone 14 PRo Max) is 500 width.
  SIDEBAR_DRAWER_WIDTH_BREAKPOINT = 600,

  // The SIDEBAR_DRAWER_WIDTH_PERMANENT_BREAKPOINT = 1100 was chosen by testing
  // and resizing window on web.
  SIDEBAR_DRAWER_WIDTH_PERMANENT_BREAKPOINT = 1100,

  //This is used to detect small devices in height.
  PHONE_SMALL_DEVICE_HEIGHT_BREAKPOINT = 599,

  // Phone detection breakpoints (diagonal inches)
  // the phone size limit it's 6.X'. Therefore I've set 7.5. I could have set 7 but a phone might have 6.9 and since the calculation is not exact
  // (PixelRatio.get() does not return the exact device density) I decided to go with 7.5
  PHONE_TABLET_BREAKPOINT = 7.5,

  // Biggest iPad 12.9' , and smallest macbook pro is 14'
  TABLET_DESKTOP_BREAKPOINT = 13.5
}

export default ResponsiveBreakpoints;
