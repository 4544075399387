import React, { useState } from "react";
import { LayoutChangeEvent, View } from "react-native";
import { useTheme } from "@rneui/themed";

import { BarPropTypes, Bar } from "react-native-progress";
import Text from "./Text";
import Spacing from "./Spacing";
import useTextStyles from "./styles/useTextStyles";

const ProgressBar = (props: BarPropTypes) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();

  const [viewWidth, setViewWidth] = useState<number>();

  const onLayout = (event: LayoutChangeEvent) => {
    const viewWidth = event.nativeEvent.layout.width;
    setViewWidth(viewWidth);
  };

  return (
    <View onLayout={onLayout}>
      <Bar
        color={theme.colors.darkBlue}
        unfilledColor={theme.colors.grey3}
        width={viewWidth}
        {...props}
      />
      <Spacing vertical={1} />

      <Text caps style={textStyles.colorDarkBlue}>
        {Math.round(props.progress * 100)}%
      </Text>
    </View>
  );
};

export default ProgressBar;
