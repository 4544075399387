import { useEffect, useMemo, useState } from "react";
import { View, Platform } from "react-native";
import { DateTime } from "luxon";
import { Divider, makeStyles, useTheme } from "@rneui/themed";
import { CalendarList } from "react-native-calendars";
import RNDatePicker from "../../ui/DatePickerComponent";

import Fonts from "../../../constants/Fonts";
import Text from "../../ui/Text";
import Spacing from "../../ui/Spacing";
import useTextStyles from "../../ui/styles/useTextStyles";
import { AnalyticsHelper_logEvent } from "common/helpers/firebase/AnalyticsHelper";
import TouchableOpacity from "../../ui/TouchableOpacity";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChanged: (date: DateTime) => void;
  onEndDateChanged: (date: DateTime) => void;
  mixpanelType: string;
}

interface DateSelectionProps {
  title: string;
  date?: DateTime;
  onPress?: () => void;
}

const RenderDateSelection = ({ title, date, onPress }: DateSelectionProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  // Disabling for web for now because it requires special UI we need to work on.
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={Platform.OS === "web" || onPress === undefined}
    >
      <Text capsSmall style={[textStyles.colorTealAAA, styles.textCenter]}>
        {title}
      </Text>
      <Spacing vertical={1} />
      <Text bodySmall style={[textStyles.colorDarkGreyBlue, styles.textCenter]}>
        {date ? date.toFormat("MMM dd yyyy") : "-"}
      </Text>
    </TouchableOpacity>
  );
};

const DateRangePickerCalendarList = ({
  startDate,
  endDate,
  onStartDateChanged,
  onEndDateChanged,
  mixpanelType
}: IProps) => {
  const { theme } = useTheme();
  const styles = useStyles();

  const [width, setWidth] = useState<number>(300);
  const [isDatePickerOpenStart, setDatePickerOpenStart] = useState<boolean>();
  const [isDatePickerOpenEnd, setDatePickerOpenEnd] = useState<boolean>();
  const [ready, setReady] = useState<boolean>(false);

  const markedDates = useMemo(() => {
    const color = theme.colors.tealBlue;
    const markedDates = {};

    if (startDate === undefined) return {};
    if (endDate === undefined) {
      markedDates[startDate.toISODate()] = {
        selected: true,
        color,
        startingDay: true,
        endingDay: true
      };
      return markedDates;
    }

    let cursor = startDate.plus({ days: 1 });
    while (cursor < endDate) {
      markedDates[cursor.toISODate()] = { selected: true, color };
      cursor = cursor.plus({ days: 1 });
    }
    markedDates[startDate.toISODate()] = {
      startingDay: true,
      color,
      selected: true
    };
    markedDates[endDate.toISODate()] = {
      endingDay: true,
      color,
      selected: true
    };

    return markedDates;
  }, [startDate, endDate]);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 500);
  }, []);

  return (
    <View style={styles.flex1}>
      <View
        style={styles.flex1}
        onLayout={(event) => {
          setWidth(event.nativeEvent.layout.width);
        }}
      >
        {ready && (
          <CalendarList
            current={DateTime.now().toISODate()}
            calendarWidth={width}
            maxDate={DateTime.now().toISODate()}
            pastScrollRange={12}
            futureScrollRange={0}
            markingType={"period"}
            markedDates={markedDates}
            showScrollIndicator
            theme={{
              calendarBackground: "transparent",
              textDayFontFamily: Fonts.Inter[600],
              textDayFontWeight: "600",
              textDayFontSize: 14,
              dayTextColor: theme.colors.darkGreyBlue,
              textMonthFontFamily: Fonts.Inter[600],
              textMonthFontWeight: "600",
              textMonthFontSize: 18,
              monthTextColor: theme.colors.darkGreyBlue
            }}
            onDayPress={({ dateString }) => {
              const selectedDate = DateTime.fromISO(dateString);

              AnalyticsHelper_logEvent(
                mixpanelType + "_DatePicker_CalendarList"
              );

              if (startDate !== undefined && endDate !== undefined) {
                onStartDateChanged(selectedDate);
                onEndDateChanged(undefined);
              } else if (startDate !== undefined && selectedDate > startDate) {
                onEndDateChanged(selectedDate);
              } else {
                onStartDateChanged(selectedDate);
              }
            }}
          />
        )}
      </View>

      <View style={styles.row}>
        <RenderDateSelection
          title="START DATE"
          date={startDate}
          onPress={() => {
            AnalyticsHelper_logEvent(mixpanelType + "_DatePicker_EndDate");
            setDatePickerOpenStart(true);
          }}
        />
        <Divider orientation="vertical" />
        <RenderDateSelection
          title="END DATE"
          date={endDate}
          onPress={() => {
            AnalyticsHelper_logEvent(mixpanelType + "_DatePicker_StartDate");
            setDatePickerOpenEnd(true);
          }}
        />
      </View>

      <RNDatePicker
        modal
        open={isDatePickerOpenEnd || isDatePickerOpenStart}
        mode={"date"}
        date={startDate ? startDate.toJSDate() : DateTime.now().toJSDate()}
        minimumDate={
          isDatePickerOpenEnd && startDate
            ? startDate.plus({ days: 1 }).toJSDate()
            : undefined
        }
        maximumDate={
          isDatePickerOpenStart && endDate
            ? endDate.minus({ days: 1 }).toJSDate()
            : DateTime.now().toJSDate()
        }
        onConfirm={(date) => {
          const luxonDate = DateTime.fromJSDate(date);
          if (isDatePickerOpenStart) onStartDateChanged(luxonDate);
          if (isDatePickerOpenEnd) onEndDateChanged(luxonDate);

          setDatePickerOpenEnd(false);
          setDatePickerOpenStart(false);
        }}
        onCancel={() => {
          setDatePickerOpenEnd(false);
          setDatePickerOpenStart(false);
        }}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    textCenter: {
      textAlign: "center"
    }
  };
});

export default DateRangePickerCalendarList;
