import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

interface IProps {
  size: number;
  index: number;
}

const StepIndicator = ({ size, index }: IProps) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      {[...Array(size)].map((e, i) => {
        return (
          <View
            key={i.toString()}
            style={i === index ? styles.activeDot : styles.inactiveDot}
          />
        );
      })}
    </View>
  );
};

const DOT_STYLE = {
  width: 53,
  height: 4,
  borderRadius: 4
};

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    marginTop: 10,
    justifyContent: "center",
    gap: 10
  },
  activeDot: {
    ...DOT_STYLE,
    backgroundColor: theme.colors.tealBlue
  },
  inactiveDot: {
    ...DOT_STYLE,
    backgroundColor: theme.colors.midBlue2
  }
}));

export default StepIndicator;
