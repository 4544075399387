import { makeStyles, useTheme } from "@rneui/themed";
import { View } from "react-native";
import { MenuItem, TextField } from "@mui/material";

import Text from "./Text";
import { onChange } from "./Dropdown.common";

interface IProps {
  label?: string;
  data?: { label: string; value: string; clearOthers?: boolean }[];
  onValueChange: (value: string[]) => void;
  multiSelect?: boolean;
  value?: string[];
}

const Dropdown = ({
  label,
  data,
  onValueChange,
  multiSelect,
  value
}: IProps) => {
  const styles = useStyles();
  const { theme } = useTheme();

  return (
    <View>
      {label && (
        <Text caps style={[styles.label]}>
          {label}
        </Text>
      )}
      {multiSelect ? (
        <TextField
          value={value !== null ? value : ""}
          select
          SelectProps={{
            multiple: true,
            MenuProps: {
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: theme.colors.secondary
                }
              }
            }
          }}
          fullWidth
          onChange={(event) => {
            // @ts-ignore
            const items = event.target.value as string[];
            onChange({ items, onValueChange, data });
          }}
        >
          {data?.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          value={value !== null ? value : ""}
          select
          fullWidth
          onChange={(event) => {
            onValueChange([event.target.value]);
          }}
        >
          {data?.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    label: {
      marginTop: 10,
      marginBottom: 5
    }
  };
});

export default Dropdown;
