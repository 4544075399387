import { Platform } from "react-native";

let Fonts;
if (Platform.OS === "web") {
  Fonts = {
    Inter: {
      400: "Inter",
      500: "Inter",
      600: "Inter",
      700: "Inter",
      italic: "Inter"
    }
  };
} else {
  Fonts = {
    Inter: {
      400: "Inter-Regular",
      500: "Inter-Medium",
      600: "Inter-SemiBold",
      700: "Inter-Bold",
      italic: "Inter-Italic"
    }
  };
}

export default Fonts;
