import { View, Image } from "react-native";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets } from "react-native-safe-area-context";

import Text from "../Text";
import Spacing from "../Spacing";
import useTextStyles from "../styles/useTextStyles";
import { rotateDevice } from "../../../assets/charts";
import { AccessibilityHelper_getAccessibilityProps } from "../../../helpers/AccessibilityHelper";
import LocalizedStrings from "../../../helpers/LocalizedStrings";

const RotateDevice = () => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View style={styles.rotateDeviceContainer}>
      <Image
        source={rotateDevice}
        style={styles.rotateDeviceImage}
        resizeMode="contain"
      />
      <Spacing vertical={5} />
      <Text
        capsSmall
        {...AccessibilityHelper_getAccessibilityProps("Rotate Device")}
        style={[textStyles.colorWhite, textStyles.textAlignCenter]}
      >
        {LocalizedStrings.screens.myReadings.rotateDevice1}
        {"\n"}
        {LocalizedStrings.screens.myReadings.rotateDevice2}
      </Text>
    </View>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    rotateDeviceContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.darkGreyBlue
    },
    rotateDeviceImage: {
      width: 64,
      height: 64
    }
  };
});
export default RotateDevice;
