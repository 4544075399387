const Biometrics_isSupported = () => {
  return Promise.resolve(false);
};

const Biometrics_getBiometryType = () => {
  return Promise.reject("Web version not supported");
};

const Biometrics_verify = (message: string) => {
  return Promise.reject("Web version not supported");
};

const Biometrics_sensorName = (message: string) => {
  return Promise.reject("Web version not supported");
};

export {
  Biometrics_isSupported,
  Biometrics_getBiometryType,
  Biometrics_verify,
  Biometrics_sensorName
};
