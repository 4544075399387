import { makeStyles, useTheme } from "@rneui/themed";
import { View, Platform } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from "react-native-confirmation-code-field";
import Text from "./Text";
import { useEffect } from "react";

interface IProps {
  title?: string;
  length: number;
  onValueChange: (value: string) => void;
  value: string;
}

const ConfirmationCodeInput = ({
  title,
  length = 6,
  onValueChange,
  value
}: IProps) => {
  const { theme } = useTheme();
  const ref = useBlurOnFulfill({ value, cellCount: length });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: onValueChange
  });
  const styles = useStyles();

  useEffect(() => {
    // handle paste from clipboard
    // https://github.com/retyui/react-native-confirmation-code-field/issues/142
    if (Platform.OS !== "android") return;
    const writeClipboard = async () => {
      await Clipboard.setString("");
    };
    const readClipboard = async () => {
      try {
        const clipboard = await Clipboard.getString();
        const regexp = new RegExp(`^\\d{${6}}$`);
        if (regexp.test(clipboard)) {
          // set
          onValueChange(clipboard);
          clearInterval(interval);
        }
      } catch {
        console.error("Failed to read clipboard");
      }
    };
    // reset clipboard
    writeClipboard();
    const interval = setInterval(() => {
      // check clipboard every second
      readClipboard();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <View style={styles.root}>
      {title !== undefined && (
        <Text capsSmall style={styles.title}>
          {title}
        </Text>
      )}
      <CodeField
        testID={"OTP"}
        ref={ref}
        {...props}
        caretHidden={false}
        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
        value={value}
        onChangeText={onValueChange}
        cellCount={length}
        rootStyle={styles.codeFieldRoot}
        // @ts-ignore bad type on library
        textInputStyle={disableWebOutline}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => {
          let symbolToRender = "X";
          if (isFocused) symbolToRender = undefined;
          else if (symbol.length > 0) symbolToRender = symbol;

          return (
            <View
              key={index}
              style={[styles.cell, isFocused && styles.focusCell]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              <Text
                bodyLarge
                style={{
                  color:
                    symbolToRender === "X"
                      ? theme.colors.grey3
                      : theme.colors.darkGreyBlue
                }}
              >
                {symbolToRender || (isFocused ? <Cursor /> : null)}
              </Text>
            </View>
          );
        }}
      />
    </View>
  );
};

const disableWebOutline = Platform.select({
  web: {
    outlineWidth: 0,
    outlineStyle: "solid"
  }
});

const useStyles = makeStyles((theme) => ({
  root: { flex: 1, padding: 20 },
  title: {
    marginLeft: 5,
    color: theme.colors.darkGreyBlue,
    alignSelf: "center"
  },
  codeFieldRoot: {
    marginTop: 5,
    marginLeft: 5,
    flex: 1,
    justifyContent: "center"
  },
  cell: {
    marginHorizontal: 5,
    width: 40,
    height: 60,
    borderWidth: 1,
    borderColor: theme.colors.grey2,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center"
  },
  focusCell: {
    borderColor: theme.colors.tealBlue
  }
}));

export default ConfirmationCodeInput;
