import { useMemo, useState } from "react";
import { LayoutChangeEvent, Platform, View } from "react-native";

import { makeStyles } from "@rneui/themed";
import { DateTime } from "luxon";

import Text from "../ui/Text";
import useTextStyles from "../ui/styles/useTextStyles";
import Fonts from "../../constants/Fonts";
import ActivityIndicator from "../ui/ActivityIndicator";
import Spacing from "../ui/Spacing";
import TouchableOpacity from "../ui/TouchableOpacity";

interface IProps {
  testID: string;
  header: string;
  value?: string | number;
  maxFont?: number;
  measureUnit: string;
  dateTime?: DateTime;
  onPress: () => void;
  loading: boolean;
}

// We need this, depending on the view width, layout will adjust as required by design.
enum LayoutEnum {
  HORIZONTAL,
  VERTICAL
}

interface RenderDateProps {
  testID: string;
  value?: string | number;
  dateTime?: DateTime;
  dateFormat: string;
}
const RenderDate = ({
  testID,
  value,
  dateTime,
  dateFormat
}: RenderDateProps) => {
  const textStyles = useTextStyles();

  return (
    <Text
      capsSmall
      style={textStyles.colorDarkBlue}
      accessibilityLabel={testID}
      testID={testID}
      allowFontScaling={false}
    >
      {value === undefined ? "NO DATA" : dateTime.toFormat(dateFormat)}
    </Text>
  );
};

interface RenderMeasureUnitProps {
  testID: string;
  measureUnit: string;
}

const RenderMeasureUnit = ({ testID, measureUnit }: RenderMeasureUnitProps) => {
  const textStyles = useTextStyles();
  return (
    <Text bodySmall style={textStyles.colorDarkBlue} testID={testID}>
      {measureUnit}
    </Text>
  );
};

const ReadingComponent = ({
  testID,
  header,
  value,
  maxFont,
  measureUnit,
  dateTime,
  onPress,
  loading
}: IProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const [layout, setLayout] = useState<LayoutEnum>();

  const onLayout = (event: LayoutChangeEvent) => {
    const viewWidth = event.nativeEvent.layout.width;

    if (viewWidth > 250) setLayout(LayoutEnum.HORIZONTAL);
    else setLayout(LayoutEnum.VERTICAL);
  };

  const dateFormat = layout === LayoutEnum.HORIZONTAL ? "MMMM dd" : "MMM dd";

  const heightStyle = useMemo(() => {
    if (Platform.OS !== "web") return {};

    if (layout === LayoutEnum.VERTICAL) return { height: 115 };
    else return { height: 95 };
  }, [layout, Platform.OS]);

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, heightStyle]}
      accessibilityLabel={
        Platform.OS === "android"
          ? testID
          : value === undefined
            ? `${testID} No Data`
            : `${testID} Date`
      }
      onLayout={onLayout}
    >
      <View style={styles.headerContainer}>
        <Text
          style={styles.headerText}
          allowFontScaling={false}
          adjustsFontSizeToFit
          numberOfLines={1}
        >
          {header}
        </Text>
      </View>

      {loading ? (
        <View style={styles.containerLoading}>
          <ActivityIndicator size="small" />
        </View>
      ) : (
        <View
          style={[
            styles.containerInformationHorizontal,
            styles.measureContainer
          ]}
        >
          <View
            style={[
              styles.alignItemsCenter,
              styles.justifyContentCenter,
              layout !== LayoutEnum.VERTICAL && styles.measureContainerRow
            ]}
          >
            <View style={styles.row}>
              <Text
                h4
                style={textStyles.colorDarkBlue}
                testID={testID + "_value"}
                adjustsFontSizeToFit
                numberOfLines={1}
                maxFontSizeMultiplier={maxFont}
              >
                {value === undefined ? "-" : value}
              </Text>
              {layout !== LayoutEnum.VERTICAL && (
                <>
                  <Spacing horizontal={1} />
                  <RenderMeasureUnit
                    measureUnit={measureUnit}
                    testID={testID + "_measure_unit"}
                  />
                </>
              )}
            </View>

            <View style={styles.alignItemsCenter}>
              {layout === LayoutEnum.VERTICAL && (
                <>
                  <RenderMeasureUnit
                    measureUnit={measureUnit}
                    testID={testID + "_measure_unit"}
                  />

                  <RenderDate
                    value={value}
                    dateFormat={dateFormat}
                    dateTime={dateTime}
                    testID={testID + "_date"}
                  />
                </>
              )}
            </View>
          </View>
          {layout !== LayoutEnum.VERTICAL && (
            <RenderDate
              value={value}
              dateFormat={dateFormat}
              dateTime={dateTime}
              testID={testID + "_date"}
            />
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      borderRadius: 24,
      borderWidth: 1,
      borderColor: theme.colors.lightGreen,
      backgroundColor: theme.colors.white,
      alignItems: "center"
    },
    row: {
      flexDirection: "row",
      alignItems: "flex-end"
    },
    flex1: {
      flex: 1
    },
    alignItemsCenter: {
      alignItems: "center"
    },
    justifyContentCenter: {
      justifyContent: "center"
    },
    containerLoading: {
      height: 90,
      alignItems: "center",
      justifyContent: "center"
    },
    containerInformationHorizontal: {
      flex: 1,
      padding: 10
    },
    measureContainer: {
      flex: 1,
      alignItems: "center"
    },
    measureContainerRow: {
      flexDirection: "row",
      gap: 5
    },
    headerContainer: {
      width: "100%",
      paddingTop: 4,
      paddingBottom: 2,
      paddingHorizontal: 10,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      backgroundColor: theme.colors.secondary,
      alignItems: "center",
      justifyContent: "center"
    },
    headerText: {
      fontFamily: Fonts.Inter[600],
      fontWeight: "600",
      fontSize: 14,
      lineHeight: 22,
      color: theme.colors.darkBlue
    }
  };
});

export default ReadingComponent;
