import LocalizedStringsLib from "react-localization";
import en from "./languages/en";
//import es from "./languages/es";

const LocalizedStrings = new LocalizedStringsLib({
  en
  //es
  // it, (for italian)
  // etc
});

export default LocalizedStrings;
