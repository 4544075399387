import { forwardRef, Ref } from "react";
import { FlatList, View, TextInput, FlatListProps } from "react-native";
import { Divider, InputProps, makeStyles, useTheme } from "@rneui/themed";
import { Input as BaseInput } from "@rneui/base";

import useViewStyles from "./styles/useViewStyles";
import Spacing from "./Spacing";
import Input from "./Input";

interface IProps<T> extends InputProps {
  filterData?: T[];
  flatListProps?: Partial<FlatListProps<T>>;
  renderListItem?: ({ item, index }) => React.ReactElement;
}

const AutocompleteInput = <T,>(
  { filterData, renderListItem, flatListProps, ...inputProps }: IProps<T>,
  ref: Ref<TextInput & BaseInput>
) => {
  const { theme } = useTheme();
  const viewStyles = useViewStyles();
  const styles = useStyles();

  const hasToShowList = inputProps.value?.length > 0 && filterData?.length > 0;

  const noDataProps =
    inputProps.value?.length > 0 && filterData?.length === 0
      ? { rightIcon: { type: "feather", name: "x", color: theme.colors.error } }
      : {};

  return (
    <View style={hasToShowList && styles.flex1}>
      <Input {...inputProps} {...noDataProps} ref={ref} />

      <Spacing vertical={4} />

      {hasToShowList && (
        <View style={[styles.flex1, styles.noPadding]}>
          <FlatList<T>
            data={filterData}
            style={[viewStyles.cardContainer, styles.flatList]}
            renderItem={({ item, index }) => renderListItem({ item, index })}
            ItemSeparatorComponent={Divider}
            {...flatListProps}
          />
        </View>
      )}
    </View>
  );
};
const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    flatList: {
      paddingHorizontal: 20,
      flexGrow: 0
    },
    noPadding: {
      padding: 0
    }
  };
});

// https://fettblog.eu/typescript-react-generic-forward-refs/
export default forwardRef(AutocompleteInput) as <T>(
  props: IProps<T> & { ref?: Ref<TextInput & BaseInput> }
) => ReturnType<typeof AutocompleteInput>;
