import {
  View,
  ColorValue,
  ViewStyle,
  StyleProp,
  useWindowDimensions,
  Platform
} from "react-native";

import { makeStyles, useTheme } from "@rneui/themed";
import Feather from "react-native-vector-icons/Feather";

import { useAppDispatch } from "common/redux";
import { AlertButtonType, AlertProps } from "common/redux/AlertSlice";
import Text from "../ui/Text";
import Button from "../ui/Button";
import useTextStyles from "../ui/styles/useTextStyles";
import { useMemo } from "react";
import { Alert_close } from "common/helpers/AlertHelper";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";

interface IButtonProps {
  button: AlertButtonType;
  containerStyle: StyleProp<ViewStyle>;
  id?: string;
}

const RenderButton = ({ button, containerStyle, id }: IButtonProps) => {
  const dispatch = useAppDispatch();
  const { style, text, onPress } = button;

  function clickHandler() {
    onPress && onPress();
    Alert_close({ dispatch, id });
  }

  switch (style) {
    case "cancel":
      return (
        <Button
          containerStyle={containerStyle}
          onPress={clickHandler}
          type="outline"
          accessibilityLabel={text}
        >
          {text}
        </Button>
      );
    case "destructive":
      return (
        <Button
          containerStyle={containerStyle}
          variant={"destructive"}
          onPress={clickHandler}
          accessibilityLabel={text}
        >
          {text}
        </Button>
      );
    default:
      return (
        <Button
          containerStyle={containerStyle}
          onPress={clickHandler}
          accessibilityLabel={text}
        >
          {text}
        </Button>
      );
  }
};

const RenderIcon = ({ type }: { type: string }) => {
  const { theme } = useTheme();
  const styles = useStyles();

  if (type == undefined) return null;
  let color: ColorValue | undefined = undefined;
  let name = undefined;
  let style = undefined;
  switch (type) {
    case "info":
      color = theme.colors.darkBlue;
      name = "info";
      break;
    case "error":
      color = theme.colors.error;
      name = "alert-circle";
      break;
    case "warning":
      color = theme.colors.warning;
      name = "alert-circle";
      break;
    case "success":
      style = styles.iconSuccess;
      color = theme.colors.darkBlue;
      name = "check";
      break;
    default:
  }

  return (
    <View style={[styles.icon, style]}>
      <Feather name={name} size={48} color={color} />
    </View>
  );
};

interface BodyProps extends AlertProps {
  children?: React.ReactElement;
}

const ReduxModalBody = (props: BodyProps) => {
  const { title, id, content, buttons, type, row, children } = props || {};
  const styles = useStyles();
  const textStyles = useTextStyles();

  const buttonRowStyle = { flexDirection: "row", gap: 10 };
  const buttonColumnStyle = { gap: 10 };

  const buttonContainerStyle = row ? { flex: 1 } : {};

  const { width } = useWindowDimensions();

  const modalWidth = useMemo(() => {
    if (Platform.OS !== "web") return "auto";

    let modalWidth = width - 40;
    if (modalWidth > 500) return 500;
    if (modalWidth < 300) return 300;
    else return modalWidth;
  }, [width, Platform.OS]);

  return (
    <View
      style={[styles.modal, styles.gap, { width: modalWidth }]}
      testID={`${content}`}
    >
      <RenderIcon type={type} />
      <View style={[styles.textContainer, styles.gap]}>
        <Text
          h3
          style={textStyles.colorDarkGreyBlue}
          accessibilityLabel={title}
        >
          {title}
        </Text>
        <Text
          body
          style={textStyles.colorGreyBlue}
          {...AccessibilityHelper_getAccessibilityProps("Modal Description")}
        >
          {content}
        </Text>
      </View>
      <View>
        <View style={row ? buttonRowStyle : buttonColumnStyle}>
          {buttons?.map((button) => (
            <RenderButton
              containerStyle={buttonContainerStyle}
              key={button.text}
              button={button}
              id={id}
            />
          ))}
        </View>
      </View>
      {children}
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      backgroundColor: theme.colors.background,
      minWidth: 300,
      maxWidth: 500,
      alignSelf: "center",
      borderRadius: 20,
      padding: 20,
      marginHorizontal: 20
    },
    icon: {
      width: 64,
      height: 64,
      borderRadius: 32,
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center"
    },
    iconSuccess: {
      borderColor: theme.colors.darkBlue,
      backgroundColor: theme.colors.lightGreen,
      borderWidth: 1
    },
    textContainer: {
      marginBottom: 10
    },
    gap: {
      gap: 10
    }
  };
});

export default ReduxModalBody;
