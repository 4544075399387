import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { makeStyles, useTheme } from "@rneui/themed";
import { View } from "react-native";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";

import IconButton from "../ui/IconButton";
import useViewStyles from "../ui/styles/useViewStyles";

import Text from "../ui/Text";
import HeaderIconText from "./HeaderIconText";
import useTextStyles from "../ui/styles/useTextStyles";
import TouchableOpacity from "../ui/TouchableOpacity";
import LocalizedStrings from "../../helpers/LocalizedStrings";

type ScreenProp = NavigationProp<DashboardStackParamList>;

const YourCareTeam = () => {
  const navigation = useNavigation<ScreenProp>();
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  return (
    <View>
      <HeaderIconText
        text={LocalizedStrings.screens.yourCareTeam.title}
        icon="users"
      />

      <TouchableOpacity
        style={[viewStyles.cardContainer, styles.row]}
        onPress={() => navigation.navigate("YourCareTeam")}
        accessibilityLabel={"Your Care Team"}
      >
        <Text body style={[textStyles.colorGreyBlue, styles.flex1]}>
          {LocalizedStrings.screens.yourCareTeam.needHelp}
        </Text>
        <IconButton
          icon="arrow-right"
          color={theme.colors.tealBlue}
          iconColor={theme.colors.white}
          onPress={() => navigation.navigate("YourCareTeam")}
        />
      </TouchableOpacity>
    </View>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    row: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10
    },
    flex1: {
      flex: 1
    }
  };
});

export default YourCareTeam;
