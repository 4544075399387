import { RefObject, useRef, useState } from "react";
import { Animated, Easing, Platform, View } from "react-native";
import { DateTime } from "luxon";
import { makeStyles, useTheme } from "@rneui/themed";
import Feather from "react-native-vector-icons/Feather";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import PagerView from "../../ui/PagerView";
import PagerViewType from "react-native-pager-view";

import useViewStyles from "../../ui/styles/useViewStyles";
import Button from "../../ui/Button";

import Text from "../../ui/Text";
import Spacing from "../../ui/Spacing";
import useTextStyles from "../../ui/styles/useTextStyles";
import DateRangePickerCalendarList from "./DateRangePickerCalendarList";
import DateRangePickerMonthList from "./DateRangePickerMonthList";
import Modal from "../../ui/Modal";
import TouchableOpacity from "../../ui/TouchableOpacity";

interface IProps {
  isVisible: boolean;
  mixpanelType: string;
  onDateRangeSelected: (from: DateTime, to: DateTime) => void;
  onCancel: () => void;
}

interface TabBarProps {
  tabBarIndicatorValue: Animated.Value;
  // @ts-ignore
  pagerViewRef: RefObject<PagerView>;
}

const TabBar = ({ tabBarIndicatorValue, pagerViewRef }: TabBarProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const [width, setWidth] = useState<number>(0);

  const tabBarIndicatorPosition = tabBarIndicatorValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, width / 2]
  });

  return (
    <View
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setWidth(width);
      }}
    >
      <View style={styles.tabBar}>
        <TouchableOpacity onPress={() => pagerViewRef.current.setPage(0)}>
          <Text bodyLarge style={textStyles.colorDarkGreyBlue}>
            Month
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => pagerViewRef.current.setPage(1)}>
          <Text
            bodyLarge
            style={textStyles.colorDarkGreyBlue}
            accessibilityLabel={"Custom"}
          >
            Custom
          </Text>
        </TouchableOpacity>
      </View>

      <Animated.View
        style={[
          styles.tabBarIndicator,
          { transform: [{ translateX: tabBarIndicatorPosition }] }
        ]}
      />
    </View>
  );
};

const DateRangePickerModal = ({
  isVisible,
  onDateRangeSelected,
  onCancel,
  mixpanelType
}: IProps) => {
  const insets = useSafeAreaInsets();
  const { theme } = useTheme();
  const styles = useStyles(insets);
  const textStyles = useTextStyles();

  const viewStyles = useViewStyles();

  const pagerViewRef = useRef<PagerViewType>();

  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();

  const tabBarIndicatorValue = useRef(new Animated.Value(0)).current;

  const handleModalClose = () => {
    onCancel();
    animateBar(0);
  };

  const animateBar = (value: number) => {
    Animated.timing(tabBarIndicatorValue, {
      toValue: value,
      duration: 0,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== "web"
    }).start();
  };

  return (
    <Modal
      visible={isVisible}
      onRequestClose={onCancel}
      containerStyle={styles.modalContainer}
    >
      <View style={[viewStyles.cardContainer, styles.container]}>
        <View style={styles.flex1}>
          <View style={[styles.row, { justifyContent: "space-between" }]}>
            <View style={{ width: 32 }} />
            <Text bodyLarge style={textStyles.colorDarkGreyBlue}>
              Calendar
            </Text>
            <TouchableOpacity onPress={handleModalClose}>
              <Feather name={"x"} color={theme.colors.tealAAA} size={32} />
            </TouchableOpacity>
          </View>

          <TabBar
            tabBarIndicatorValue={tabBarIndicatorValue}
            pagerViewRef={pagerViewRef}
          />

          <Spacing vertical={2} />

          <PagerView
            ref={pagerViewRef}
            style={styles.flex1}
            initialPage={0}
            onPageScroll={(event) => {
              animateBar(event.nativeEvent.offset + event.nativeEvent.position);
            }}
          >
            <DateRangePickerMonthList
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
              mixpanelType={mixpanelType}
            />
            <DateRangePickerCalendarList
              startDate={startDate}
              endDate={endDate}
              onStartDateChanged={setStartDate}
              onEndDateChanged={setEndDate}
              mixpanelType={mixpanelType}
            />
          </PagerView>

          <Spacing vertical={4} />

          <Button
            title="Apply Selection"
            accessibilityLabel={"Apply Selection"}
            disabled={startDate === undefined || endDate === undefined}
            onPress={() => {
              if (startDate === undefined || endDate === undefined) onCancel();
              else onDateRangeSelected(startDate, endDate);

              animateBar(0);
            }}
          />
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    modalContainer: {
      flex: 1,
      backgroundColor: "#00000099"
    },
    container: {
      flex: 1,

      margin: 20
    },
    flex1: {
      flex: 1
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    tabBar: {
      flexDirection: "row",
      height: 50,
      alignItems: "center",
      justifyContent: "space-around"
    },
    tabBarIndicator: {
      height: 2,
      width: "50%",
      backgroundColor: theme.colors.tealBlue
    }
  };
});

export default DateRangePickerModal;
