import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";

interface IProps {
  style?: StyleProp<ViewStyle>;
  initialPage?: number;
  children: React.ReactNode | React.ReactNode[];
  onPageScroll: (event) => void;
}

const PagerView = forwardRef(
  ({ style, initialPage, children, onPageScroll }: IProps, ref) => {
    const [page, setPage] = useState<number>(initialPage);

    useEffect(() => {
      onPageScroll({
        nativeEvent: {
          offset: 0,
          position: page
        }
      });
    }, [page]);

    useImperativeHandle(ref, () => ({
      setPage: (page: number) => setPage(page)
    }));

    return children[page];
  }
);

export default PagerView;
