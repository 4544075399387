import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import Text from "../../components/ui/Text";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { getNameOrUsername } from "common/helpers/helpers";
import ActivityIndicator from "../ui/ActivityIndicator";

const PatientHeader = () => {
  const styles = useStyles();

  const { data, isLoading } = useGetAuthenticatedMember();

  return (
    <View style={styles.row}>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <View>
          <Text h4 testID="patientName">
            {getNameOrUsername(data?.patient, false, false)}
          </Text>
        </View>
      )}
    </View>
  );
};

const AVATAR_SIZE = 72;
const MARGIN_SIDES = 20;

const useStyles = makeStyles((theme) => {
  return {
    row: {
      flexDirection: "row",
      gap: MARGIN_SIDES,
      margin: MARGIN_SIDES,
      alignItems: "center"
    },

    roundedImage: {
      height: AVATAR_SIZE,
      width: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 2
    }
  };
});

export default PatientHeader;
