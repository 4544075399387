import { View } from "react-native";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "common/redux";

import ReduxModalBody from "./ReduxModalBody";
import { Alert_close } from "common/helpers/AlertHelper";
import Modal from "../ui/Modal";

const ReduxModal = () => {
  const dispatch = useAppDispatch();

  const { visibleModals } = useSelector((state: RootState) => state.alert);
  const props = visibleModals?.[visibleModals?.length - 1];
  const visible = props?.visible ?? false;

  const onRequestClose = () => {
    Alert_close({ dispatch, id: props?.id });
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      {props !== undefined ? <ReduxModalBody {...props} /> : <View />}
    </Modal>
  );
};

export default ReduxModal;
