import {
  Text as TextLib,
  TextProps,
  ThemeOptions,
  useTheme
} from "@rneui/themed";
import useTextStyles from "./styles/useTextStyles";

interface IProps extends TextProps {
  children: React.ReactNode;
  largeTitle?: boolean;
  bodyLarge?: boolean;
  body?: boolean;
  bodySmall?: boolean;
  caps?: boolean;
  capsSmall?: boolean;
  link?: boolean;
  linkLarge?: boolean;
  italic?: boolean;
}

const getStyle = (props: IProps, theme: ThemeOptions, textStyles) => {
  if (props.largeTitle) return textStyles.largeTitle;
  else if (props.bodyLarge) return textStyles.bodyLarge;
  else if (props.body) return textStyles.body;
  else if (props.bodySmall) return textStyles.bodySmall;
  else if (props.caps) return textStyles.caps;
  else if (props.capsSmall) return textStyles.capsSmall;
  else if (props.link) return textStyles.link;
  else if (props.linkLarge) return textStyles.linkLarge;
  else if (props.italic) return textStyles.italic;
};

const Text = (props: IProps) => {
  const { theme } = useTheme();
  const textStyles = useTextStyles();
  const style = getStyle(props, theme, textStyles);

  return (
    <TextLib
      h1Style={[textStyles.h1Style, props.style]}
      h2Style={[textStyles.h2Style, props.style]}
      h3Style={[textStyles.h3Style, props.style]}
      h4Style={[textStyles.h4Style, props.style]}
      {...props}
      style={[style, props.style]}
    >
      {props.children}
    </TextLib>
  );
};

export default Text;
