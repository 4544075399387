import { useMemo, useState } from "react";
import { View, FlatList } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import CheckBox from "../CheckBox";
import Input from "../Input";
import Button from "../Button";
import Text from "../Text";
import useTextStyles from "../styles/useTextStyles";
import Modal from "../Modal";
import TouchableHighlight from "../TouchableHighlight";

interface DataType {
  label: string;
  value: string;
}

interface IProps {
  visible: boolean;
  handleModalClose: () => void;
  data: DataType[];
  selectedItems: string[];
  onSelectedItemsChange: (selectedItems: string[]) => void;
  multipleSelection?: boolean;
}

const PickerModal = ({
  visible,
  handleModalClose,
  data,
  multipleSelection = false,
  selectedItems,
  onSelectedItemsChange
}: IProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();

  const [searchText, setSearchText] = useState<string>("");

  const filteredData = useMemo(() => {
    if (searchText === "") return data;
    else {
      return data.filter(({ label }) =>
        label.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }
  }, [searchText]);

  return (
    <Modal visible={visible} onRequestClose={handleModalClose}>
      <View style={styles.modalContainer}>
        <View style={styles.innerContainer}>
          <View style={styles.containerHorizontal}>
            <Input
              value={searchText}
              onChangeText={(text) => setSearchText(text)}
              placeholder="Enter search text here"
              accessibilityLabel={"Search State Field"}
            />
          </View>

          <FlatList<DataType>
            data={filteredData}
            showsVerticalScrollIndicator={false}
            extraData={selectedItems}
            renderItem={({ item }) => {
              const checked = selectedItems.includes(item.value);
              return (
                <View>
                  {multipleSelection ? (
                    <CheckBox
                      checked={checked}
                      containerStyle={styles.containerHorizontal}
                      onPress={() => {
                        const index = selectedItems.indexOf(item.value);
                        if (index >= 0) {
                          selectedItems.splice(index, 1);
                          onSelectedItemsChange([...selectedItems]);
                        } else {
                          onSelectedItemsChange([...selectedItems, item.value]);
                        }
                      }}
                      title={item.label}
                    />
                  ) : (
                    <TouchableHighlight
                      style={styles.textRow}
                      underlayColor={theme.colors.white}
                      onPress={() => {
                        onSelectedItemsChange([item.value]);
                        handleModalClose();
                      }}
                    >
                      <Text
                        bodyLarge
                        style={[textStyles.colorDarkGreyBlue]}
                        accessibilityLabel={item.label}
                      >
                        {item.label}
                      </Text>
                    </TouchableHighlight>
                  )}
                </View>
              );
            }}
          />

          <Button
            title={"Close"}
            onPress={handleModalClose}
            containerStyle={styles.containerHorizontal}
          />
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    modalContainer: {
      backgroundColor: theme.colors.white,
      width: "90%",
      maxWidth: 600,
      height: "80%",
      alignSelf: "center",
      borderRadius: 10,
      overflow: "hidden"
    },
    innerContainer: {
      flex: 1,
      marginVertical: 20,
      gap: 10
    },
    containerHorizontal: {
      marginHorizontal: 20
    },
    textRow: {
      height: 40,
      justifyContent: "center",
      paddingHorizontal: 20
    }
  };
});

export default PickerModal;
