import { StyleProp, ViewStyle } from "react-native";
import { makeStyles } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";
import TouchableOpacity from "./TouchableOpacity";

interface IProps {
  type?: "solid" | "outline";
  shape?: "circular" | "square";
  color: string;
  buttonSize?: number;
  icon: string;
  iconSize?: number;
  iconColor?: string;
  onPress?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const IconButton = ({
  type = "solid",
  shape = "circular",
  color,
  buttonSize = 30,
  icon,
  iconSize = 24,
  iconColor,
  onPress = undefined,
  disabled = false,
  children,
  style
}: IProps) => {
  const styles = useStyles({ type, color, buttonSize, shape });

  return (
    <TouchableOpacity
      accessibilityLabel={"Icon"}
      style={[styles.container, style]}
      disabled={disabled}
      onPress={onPress}
    >
      <Feather
        name={icon}
        size={iconSize}
        color={iconColor}
        accessibilityLabel={icon}
      />
      {children}
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme, { type, color, buttonSize, shape }) => {
  return {
    container: {
      width: buttonSize,
      height: buttonSize,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: shape === "circular" ? buttonSize / 2 : buttonSize / 6,
      backgroundColor: type === "solid" ? color : "transparent",
      borderWidth: type === "outline" ? 1 : 0,
      borderColor: type === "outline" ? color : "transparent"
    }
  };
});

export default IconButton;
